import React from "react"

import SEO from "../components/seo"
import { Link } from "gatsby"
import ChevronLeftIcon from "../components/common/icons/ChevronLeftIcon"

const NotFoundPage = () => (
  <>
    <SEO pageTitle="404: Not found" ogTitle="404: Not found" />
    <main className="flex flex-col items-center justify-center min-h-screen">
      <div>
        <h1 className="text-5xl font-bold tracking-tight text-gray-800">
          Page not found
        </h1>
        <p className="my-8 text-2xl font-thin tracking-tight">
          Sorry, we couldn’t find what you were looking for.
        </p>
        <div>
          <strong>Need help finding something?</strong>
        </div>
        <p className="max-w-lg">
          Email support@fundraisify.com and we’ll get back to you right away.
        </p>
        <div className="my-4">
          <Link to="/" className="inline-flex items-center text-blue-600">
            <ChevronLeftIcon /> Fundraisify Homepage
          </Link>
        </div>
      </div>
    </main>
  </>
)

export default NotFoundPage
